import { FC, useEffect, useRef } from 'react';
import { useCountUp, CountUpProps } from 'react-countup';
import { useIntersection } from '../hooks/screen/useIntersection';

/**
 * react-countup documentation: https://github.com/glennreyes/react-countup
 */
const CountUpOnceVisible: FC<CountUpProps> = (countUpProps) => {
  const countUpRef = useRef(null);
  const becameVisible = useIntersection(countUpRef, '0px');
  const { start, update } = useCountUp({
    ...countUpProps,
    ref: countUpRef
  });

  if (becameVisible) {
    start();
  }

  useEffect(() => {
    update(countUpProps.end);
  }, [countUpProps.end]);

  return <span className="inline-block" ref={countUpRef} />;
};

export default CountUpOnceVisible;
