import { FC } from 'react';

import { SvgWrapper } from '../svgWrapper';
import { ColoredShapeSvgProps, DecorativeShapeSvgProps } from './types';
import TAILWIND_CSS_CONFIG from '../../../../tailwind.config';

// =========================================
// BASE RECTANGLE
// =========================================

const RectangleSvg: FC<DecorativeShapeSvgProps> = ({ fill, fillOpacity, className = '' }) => (
  <SvgWrapper viewBox="0 0 404 175" className={className}>
    <rect width="404" height="175" rx="16" fill={fill} fillOpacity={fillOpacity} />
  </SvgWrapper>
);

// =========================================
// COLORED RECTANGLES
// =========================================

export const PinkRectangleSvg: FC<ColoredShapeSvgProps> = ({ className = '', fillOpacity }) => (
  <RectangleSvg className={className} fill={TAILWIND_CSS_CONFIG.theme.colors.magenta['500']} fillOpacity={fillOpacity} />
);

export const YellowRectangleSvg: FC<ColoredShapeSvgProps> = ({ className = '', fillOpacity }) => (
  <RectangleSvg className={className} fill={TAILWIND_CSS_CONFIG.theme.colors.logo.gold} fillOpacity={fillOpacity} />
);

export const OrangeRectangleSvg: FC<ColoredShapeSvgProps> = ({ className = '', fillOpacity }) => (
  <RectangleSvg className={className} fill={TAILWIND_CSS_CONFIG.theme.colors.logo.orange} fillOpacity={fillOpacity} />
);

export const GreenRectangleSvg: FC<ColoredShapeSvgProps> = ({ className = '', fillOpacity }) => (
  <RectangleSvg className={className} fill={TAILWIND_CSS_CONFIG.theme.colors.logo.lime} fillOpacity={fillOpacity} />
);

export const BlueRectangleSvg: FC<ColoredShapeSvgProps> = ({ className = '', fillOpacity }) => (
  <RectangleSvg className={className} fill={TAILWIND_CSS_CONFIG.theme.colors.logo.turquoise} fillOpacity={fillOpacity} />
);

export const SkyBlueRectangleSvg: FC<ColoredShapeSvgProps> = ({ className = '', fillOpacity }) => (
  <RectangleSvg className={className} fill={TAILWIND_CSS_CONFIG.theme.colors['sky-blue']['500']} fillOpacity={fillOpacity} />
);
