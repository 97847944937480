import type { FC } from 'react';

interface HowItWorksItemProps {
  readonly label: string;
  readonly description: string;
}

/**
 * Component for How It Works List Item
 */
const HowItWorksItem: FC<HowItWorksItemProps> = ({ label, description }) => {
  return (
    <article>
      <p className="mb-2 font-inter text-20spx font-bold leading-6 lg:text-24spx  lg:leading-normal">{label}</p>
      <p className="text-16spx leading-tight text-smokey-gray-500 lg:text-18spx">{description}</p>
    </article>
  );
};

export default HowItWorksItem;
