import type { FC } from 'react';

import { DELIVERY_DAYS } from '../constants/deliveryDays';
import { deconstruct, useLocalizer } from '../localization';
import { MarketingOverline, MarketingP1 } from './text';

interface ShippingMetricsProps {
  readonly colorVariant?: 'peach' | 'blue' | 'magenta';
  readonly className?: string;
}

export const ShippingMetrics: FC<ShippingMetricsProps> = (props) => {
  const { colorVariant, className = '' } = props;
  const loc = useLocalizer();

  const ZeroDamageClaims = loc.HomeMain.ZeroDamageClaims;
  const ZeroDamageClaimsTup = deconstruct(ZeroDamageClaims);
  const PercentSatisfaction = loc.HomeMain.PercentSatisfaction;
  const PercentSatisfactionTup = deconstruct(PercentSatisfaction);

  let metricsColorClassName;
  switch (colorVariant) {
    case 'peach':
      metricsColorClassName = 'text-peach';
      break;
    case 'blue':
      metricsColorClassName = 'text-sky-blue-1000-old';
      break;
    case 'magenta':
      metricsColorClassName = 'text-magenta-500';
  }

  return (
    <div className={`flex flex-col flex-wrap justify-center gap-6 lg:flex-row ${className}`}>
      <div className="flex w-full flex-1 flex-col justify-center rounded-2xl bg-sky-blue-200-old px-4 py-8 text-center">
        <div className={`mb-4 ${metricsColorClassName}`}>
          <div className="font-inter text-96spx font-bold leading-tight">11</div>
          <MarketingOverline>{loc.Services.DayDelivery}</MarketingOverline>
        </div>
        <MarketingP1 className="px-8 lg:px-0">
          {loc.HomeMain.DomesticInternationalDelivery(DELIVERY_DAYS.DOMESTIC, DELIVERY_DAYS.INTERNATIONAL)}
        </MarketingP1>
      </div>

      <div className="flex w-full flex-1 flex-col justify-center rounded-2xl bg-sky-blue-200-old px-4 py-8 text-center">
        <div className={`mb-4 ${metricsColorClassName}`}>
          <MarketingOverline>{ZeroDamageClaimsTup[0]}</MarketingOverline>
          <div className="font-inter text-96spx font-bold leading-tight">{ZeroDamageClaimsTup[1]}</div>
          <MarketingOverline>{ZeroDamageClaimsTup[2]}</MarketingOverline>
        </div>
        <MarketingP1 className="px-8 lg:px-0">{loc.Services.TrackRecordClaims}</MarketingP1>
      </div>

      <div className="flex w-full flex-1 flex-col justify-center rounded-2xl bg-sky-blue-200-old px-4 py-8 text-center">
        <div className={`mb-4 ${metricsColorClassName}`}>
          <MarketingOverline>{PercentSatisfactionTup[0]}</MarketingOverline>
          <div className="font-inter text-96spx font-bold leading-tight">{PercentSatisfactionTup[1]}</div>
          <MarketingOverline>{PercentSatisfactionTup[2]}</MarketingOverline>
        </div>
        <MarketingP1 className="px-8 lg:px-0">{loc.Services.SatisfactionRate}</MarketingP1>
      </div>
    </div>
  );
};
