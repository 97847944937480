import { FC, useEffect, useState } from 'react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

interface SanitizedMarkdownProps {
  readonly className?: string;
  readonly markdownText?: string;
  regexFilter?: RegExp;
}

/**
 *
 */
export const SanitizedMarkdown: FC<SanitizedMarkdownProps> = ({ className = '', markdownText, regexFilter }) => {
  const [sanitizedHTML, setSanitizedHTML] = useState('');

  useEffect(() => {
    if (typeof markdownText !== 'undefined') {
      let html = DOMPurify.sanitize(marked.parse(markdownText));

      if (regexFilter) {
        const matchedText = html.match(regexFilter);
        if (matchedText) {
          html = matchedText.join('');
        }
      }

      setSanitizedHTML(marked.parse(html));
    }
  }, [markdownText]);

  return <div className={`sanitized-markdown ${className}`} dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
};
