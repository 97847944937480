import type { FC } from 'react';

import dynamic from 'next/dynamic';

import { useLocalizer } from '../../../localization';

const TestimonialsCarousel = dynamic(() => import('../../../bits/carousels/testimonialsCarousel'), { ssr: false });

/**
 *
 */
const TestimonialsWidget: FC = () => {
  const loc = useLocalizer();

  return (
    <>
      <div className="mb-10 flex flex-col items-center gap-x-28 gap-y-10 lg:mb-20 lg:flex-row">
        <div className="flex-1">
          <div className="flex flex-col gap-y-4 font-inter">
            <div className="font-lato text-16spx uppercase leading-tight tracking-widest text-magenta-500-old">
              {loc.Common.Testimonials}
            </div>
            <h2 className="font-inter text-36spx font-bold leading-tight text-moov-blue-500 lg:text-48spx">
              {loc.Common.TrustedByLeaders}
            </h2>
            <h6 className="font-lato text-16spx leading-tight text-smokey-gray-500 lg:text-20spx">
              {loc.Testimony.TopManufacturersChooseMoov}
            </h6>
          </div>
        </div>
      </div>

      {/* TESTIMONIALS CAROUSEL */}
      <div className="-mx-content-x-mobile lg:mx-0">
        <TestimonialsCarousel colorVariant="magenta" />
      </div>
    </>
  );
};

export default TestimonialsWidget;
