import { FC, useState } from 'react';

import dynamic from 'next/dynamic';
import Image from 'next/image';

import PlayCircle from '@mui/icons-material/PlayCircle';

import { runOnSpacebarOrEnterPress } from '../../../utils';
import { HeroWithVideoProps } from './heroWithVideo';
import { Tooltip } from '@mui/material';
import { PinkSquareSvg, YellowSquareSvg } from '../../../bits/svgs/decorativeShapes/square';
import { BlueRectangleSvg, OrangeRectangleSvg } from '../../../bits/svgs/decorativeShapes/rectangle';

const VideoModal = dynamic(() => import('../../../bits/modals/videoModal'), { ssr: false });

interface HeroWithVideoAndAnimationProps extends HeroWithVideoProps {
  readonly tooltipMessage?: string;
}

export const HeroWithVideoAndAnimation: FC<HeroWithVideoAndAnimationProps> = (props) => {
  const { children, videoSrc, thumbnailSrc, className = '', tooltipMessage } = props;

  const [modal, setModal] = useState<JSX.Element | null>(null);

  const openVideoModal = () => {
    setModal(<VideoModal setModal={setModal} videoSrc={videoSrc} />);
  };

  return (
    <>
      {modal}

      <section className={`flex flex-col items-center gap-x-4 gap-y-16 lg:flex-row ${className}`}>
        {/* CONTENT */}
        <article className="flex-4">{children}</article>

        {/* DESKTOP */}
        <div className="relative w-full flex-5 py-[28%] lg:block lg:py-[16.5%]">
          {/* SQUARES */}
          <PinkSquareSvg className="absolute left-[21%] top-[-10%] w-[25.7%] animate-we-are-growing-pink-square" fillOpacity={0.25} />
          <YellowSquareSvg className="absolute right-0 top-[7%] w-[25.7%] animate-we-are-growing-yellow-square" fillOpacity={0.25} />
          <BlueRectangleSvg className="absolute bottom-[5%] left-0 w-[66.5%] animate-we-are-growing-blue-rectangle" fillOpacity={0.4} />
          <OrangeRectangleSvg
            className="absolute bottom-0 right-[4%] w-[66.5%] animate-we-are-growing-orange-rectangle"
            fillOpacity={0.2}
          />

          <div
            className="absolute top-0 flex w-full items-center justify-center"
            onClick={openVideoModal}
            onKeyDown={(event) => runOnSpacebarOrEnterPress(event, openVideoModal)}
            tabIndex={0}
          >
            <div className="relative w-[85%] cursor-pointer overflow-hidden rounded-3xl py-[25%] lg:w-[80%]">
              {/* VIDEO */}
              <Image src={thumbnailSrc} alt="image" layout="fill" objectFit="cover" quality={100} />

              {/* PLAY VIDEO ICON */}
              <Tooltip
                classes={{ tooltip: 'text-16spx bg-magenta-500 text-white px-3 py-2 font-lato', arrow: 'text-magenta-500' }}
                title={tooltipMessage}
                placement="top"
                arrow
                enterDelay={0}
                enterTouchDelay={0}
              >
                <div className="absolute-center group">
                  <div className="absolute-center h-34 w-34 rounded-full bg-magenta-500 opacity-0 transition-opacity duration-300 ease-linear group-hover:opacity-25"></div>
                  <div className="absolute-center h-10 w-10 bg-magenta-500 opacity-75" />
                  <div className="relative text-[7.25rem] text-white">
                    <PlayCircle fontSize="inherit" />
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
