import type { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import Image from 'next/image';

import 'swiper/css';
import 'swiper/css/pagination';
import { IMAGE_URLS } from '@/src/constants/imageUrls';
import { useLocalizer } from '@/src/localization';
const FeaturesSlider: FC = () => {
  const loc = useLocalizer();
  return (
    <>
      <div className="hero-carousel relative">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          pagination={{ clickable: true }}
          autoplay={{ delay: 10000 }}
          className="!pb-16"
        >
          <SwiperSlide>
            <h2 className="font-inter text-36spx font-bold leading-tight lg:text-48spx">{loc.HomeMain.ToolRequests}</h2>
            <p className="m-auto max-w-4xl text-20spx text-smokey-gray-500">{loc.HomeMain.SimplifiesToolRequests}</p>
            <div className=" mt-8 lg:mt-16">
              <Image src={IMAGE_URLS.FEATURES_TOOL_REQUESTS} alt={loc.HomeMain.ToolRequests} width="1186" height="816" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <h2 className="font-inter text-36spx font-bold leading-tight lg:text-48spx">{loc.HomeMain.ManageListings}</h2>
            <p className="m-auto max-w-4xl text-20spx text-smokey-gray-500">{loc.HomeMain.QuickAndEffortlessListingCreation}</p>
            <div className="mt-16">
              <Image src={IMAGE_URLS.FEATURES_MANAGES_LISTINGS} alt={loc.HomeMain.ManageListings} width="1186" height="816" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <h2 className="font-inter text-36spx font-bold leading-tight lg:text-48spx">{loc.HomeMain.DetailedTracking}</h2>
            <p className="m-auto max-w-4xl text-20spx text-smokey-gray-500">{loc.HomeMain.DetailedGPSTrackingWorldwide}</p>
            <div className="mt-16">
              <Image src={IMAGE_URLS.FEATURES_DETAILED_TRACKING} alt={loc.HomeMain.DetailedTracking} width="1186" height="816" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};
export default FeaturesSlider;
