import { FC } from 'react';
import { SetModal } from '../../entities/common';
import { useModal } from '../../hooks/useModal';

import Modal from './modal';

interface Props {
  readonly setModal: SetModal;
  readonly videoSrc: string;
}

/**
 *
 */
const VideoModal: FC<Props> = ({ setModal, videoSrc }) => {
  const { isOpen, onClose } = useModal(setModal);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="absolute-center h-[56vw] w-screen bg-black lg:h-[80vh] lg:w-[80vw]">
        <iframe src={videoSrc} height="100%" width="100%" allow="fullscreen" loading="lazy" />
      </div>
    </Modal>
  );
};

export default VideoModal;
