import { FC } from 'react';

import { SvgWrapper } from '../svgWrapper';
import { ColoredShapeSvgProps, DecorativeShapeSvgProps } from './types';
import TAILWIND_CSS_CONFIG from '../../../../tailwind.config';

// =========================================
// BASE SQUARE
// =========================================

const SquareSvg: FC<DecorativeShapeSvgProps> = ({ fill, fillOpacity, className = '' }) => (
  <SvgWrapper viewBox="0 0 200 200" className={className}>
    <rect width="200" height="200" rx="16" fill={fill} fillOpacity={fillOpacity} />
  </SvgWrapper>
);

// =========================================
// COLORED SQUARES
// =========================================

export const PinkSquareSvg: FC<ColoredShapeSvgProps> = ({ className = '', fillOpacity }) => (
  <SquareSvg className={className} fill={TAILWIND_CSS_CONFIG.theme.colors.magenta['500']} fillOpacity={fillOpacity} />
);

export const YellowSquareSvg: FC<ColoredShapeSvgProps> = ({ className = '', fillOpacity }) => (
  <SquareSvg className={className} fill={TAILWIND_CSS_CONFIG.theme.colors.logo.gold} fillOpacity={fillOpacity} />
);

export const OrangeSquareSvg: FC<ColoredShapeSvgProps> = ({ className = '', fillOpacity }) => (
  <SquareSvg className={className} fill={TAILWIND_CSS_CONFIG.theme.colors.logo.orange} fillOpacity={fillOpacity} />
);

export const GreenSquareSvg: FC<ColoredShapeSvgProps> = ({ className = '', fillOpacity }) => (
  <SquareSvg className={className} fill={TAILWIND_CSS_CONFIG.theme.colors.logo.lime} fillOpacity={fillOpacity} />
);

export const BlueSquareSvg: FC<ColoredShapeSvgProps> = ({ className = '', fillOpacity }) => (
  <SquareSvg className={className} fill={TAILWIND_CSS_CONFIG.theme.colors.logo.turquoise} fillOpacity={fillOpacity} />
);

export const SkyBlueSquareSvg: FC<ColoredShapeSvgProps> = ({ className = '', fillOpacity }) => (
  <SquareSvg className={className} fill={TAILWIND_CSS_CONFIG.theme.colors['sky-blue']['500']} fillOpacity={fillOpacity} />
);
