import type { GetStaticProps, NextPage } from 'next';
import { captureException, Scope } from '@sentry/nextjs';

import HomeMain, { HomeProps } from '../corporate/homeMain';
import { fallbackCompaniesTotalCount } from '../constants/companies';
import { fallbackListingsTotalCount, fallbackListingsTotalValue } from '../constants/listings';
import { fetchBlogPosts } from '../providers/blog';
import { fetchCompaniesMetrics } from '../providers/companies';
import { fetchMoovMetrics } from '../providers/listings';
import { BlogPost } from '../entities/blog';
import { fetchConfig } from '@/src/corporate/homeMain/services';
import { GlobalConfig } from '../corporate/homeMain/types';

/**
 *
 */
export const IndexPage: NextPage<HomeProps> = ({ moov_metrics, blog_posts, companies_total_count, config }) => {
  return <HomeMain moov_metrics={moov_metrics} blog_posts={blog_posts} companies_total_count={companies_total_count} config={config} />;
};

export default IndexPage;

/**
 * TODO 183428688 Sentry Scope is deprecated, should now be using configureScope.
 */
// -- Sentry Set Scope
const scope = new Scope();
scope.setTag('page', 'HomeMain');
scope.setTag('file', 'src/pages/index.tsx');

export const getStaticProps: GetStaticProps<HomeProps> = async () => {
  let moov_metrics = { listings_total_count: fallbackListingsTotalCount, listings_total_value: fallbackListingsTotalValue };
  try {
    moov_metrics = await fetchMoovMetrics();
  } catch (err) {
    captureException(err, scope);
  }

  let blog_posts: BlogPost[] = [];
  try {
    const { posts } = await fetchBlogPosts(1, 4);
    blog_posts = posts;
  } catch (err) {
    captureException(err, scope);
  }

  let config: GlobalConfig = {
    banner: {
      content: '',
      enabled: false,
      url: ''
    },
    logos: []
  };
  try {
    config = await fetchConfig();
  } catch (err) {
    captureException(err, scope);
  }

  let companies_total_count = fallbackCompaniesTotalCount;
  try {
    const { total_count } = await fetchCompaniesMetrics();
    companies_total_count = total_count;
  } catch (err) {
    captureException(err, scope);
  }

  return { props: { moov_metrics, blog_posts, companies_total_count, config }, revalidate: 1 * 60 * 60 * 12 };
};
