import { MutableRefObject, useState, useEffect } from 'react';
import { isElementInViewport } from '../../../utils';

/**
 * useIntersection hook checks if an element is within view
 * rootMargin increases/decreases how much an element needs to be exposed before it's considered visible
 */
export const useIntersection = (ref: MutableRefObject<HTMLElement | null>, rootMargin: string) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const element = ref.current;
    if (element !== null && !isElementInViewport(element)) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsVisible(entry.isIntersecting);

          // stop checking once element comes into view
          if (entry.isIntersecting) {
            observer.unobserve(element);
          }
        },
        { rootMargin }
      );
      observer.observe(element);
      return () => observer.unobserve(element);
    }
  }, [ref, rootMargin]);

  return isVisible;
};
