import { FC, useEffect } from 'react';

import Image from 'next/image';
import { useRouter } from 'next/router';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { useUser } from '../../hooks/useUser';
import CountUpOnceVisible from '../../bits/countUpOnceVisible';
import Head from '../../bits/head';
import { ExternalLink, InternalLink, LinkWithRightArrow } from '../../bits/links';
import { MarketingH1, MarketingH3, MarketingH5, MarketingOverlineMagenta, MarketingSubheading } from '../../bits/text';
import { EXTERNAL_URLS } from '../../constants/externalUrls';
import { IMAGE_URLS } from '../../constants/imageUrls';
import routes from '../../routes';

import { GTM_EVENT_TYPES } from '../../integrations/googleTagManager/constants';
import { ContentWrapper, FullWidthContentWrapper, PageWrapperCustom } from '../../layout/contentWrappers';
import { HomeEquipmentSearchBar } from '../../layout/equipmentSearchBars';
import { deconstruct, useLocalizer } from '../../localization';

import Gradient from './gradient';
import { SanitizedMarkdown } from '../../bits/sanitizedMarkdown';
import HowItWorksItem from '../components/howItWorksItem';
import TestimonialsWidget from '../components/testimonialsWidget';
import { formatDate } from '../../utils';
import { BlogPost } from '../../entities/blog';
import { MoovMetrics } from '../../entities/metrics';
import { ShippingMetrics } from '../../bits/shippingMetrics';
import { MoovButton } from '../../bits/moovButton';
import { useGTM } from '../../hooks/useGTM';
import FeaturesSlider from '../components/featuresSlider';
import { HeroWithVideoAndAnimation } from '../components/fancyHeroWidgets/heroWithVideoAndAnimation';

import { GlobalConfig } from './types';
import { Divider, Stack } from '@mui/material';
import { colors, spacing } from '@/src/design/designConstants';
import { useAppDispatch } from '@/src/store';
import { GlobalModalTypes, openModal } from '@/src/state/globalModal/slice';

export interface HomeProps {
  readonly moov_metrics: MoovMetrics;
  readonly blog_posts: BlogPost[];
  readonly companies_total_count: number;
  config: GlobalConfig;
}

/**
 *
 */
const HomeMain: FC<HomeProps> = ({ moov_metrics, blog_posts, companies_total_count, config }) => {
  const router = useRouter();
  const { user, userIsLoggedIn } = useUser();
  const loc = useLocalizer();
  const dispatch = useAppDispatch();
  const NewWayToBuySell = loc.HomeMain.NewWayToBuySell;
  const NewWayToBuySellTup = deconstruct(NewWayToBuySell);
  const OverXCompaniesAndGrowing = loc.HomeMain.OverXCompaniesAndGrowing;
  const OverXCompaniesAndGrowingTup = deconstruct(OverXCompaniesAndGrowing);

  const { trackGTMEvent } = useGTM();
  /**
   * Logged-in user redirect to dashboard
   */
  if (userIsLoggedIn(user)) {
    router.push({ pathname: routes.dashboard() });
  }

  const canvasSelectorId = 'animated-gradient-canvas';
  useEffect(() => {
    const canvasEl = document.getElementById(canvasSelectorId);
    const gradient = new Gradient(canvasEl);
    return () => {
      gradient.disconnect();
    };
  }, [canvasSelectorId]);

  const handleRegister = () => {
    trackGTMEvent({
      event: GTM_EVENT_TYPES.register.landingClick
    });
  };

  const handleContactClick = () => {
    dispatch(
      openModal({
        modalType: GlobalModalTypes.CONTACT_SALES,
        modalProps: {}
      })
    );
  };

  const getVideoURL = () => {
    switch (router.locale) {
      case 'zh-CN':
        return EXTERNAL_URLS.INTRO_VIDEO_IN_CHINESE_URL;
      case 'zh-TW':
        return EXTERNAL_URLS.INTRO_VIDEO_IN_TAIWAN_URL;
      default:
        return EXTERNAL_URLS.INTRO_VIDEO_IN_ENGLISH_URL;
    }
  };

  return (
    <>
      <Head title={loc.HomeMain.UsedSemiconductorEquipmentMarketplace} description={loc.HomeMain.SeeWhyManufacturersTrustMoov} />

      {/* ANNOUNCEMENT BANNER */}
      {config.banner.enabled && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          p={spacing.xxs}
          sx={{ backgroundColor: colors.moovBlue[500], color: colors.surface.white }}
        >
          <div className="gap-x-6 gap-y-2 py-2 text-center text-18spx lg:text-20spx">{config.banner.content}</div>
          <Divider flexItem light orientation="vertical" sx={{ bgcolor: colors.surface.white, mx: spacing.lg, my: spacing.sm }} />
          <a href={config.banner.url} rel="noreferrer" target="_blank">
            {loc.Magic.LearnMore}
          </a>
        </Stack>
      )}

      <PageWrapperCustom className="text-moov-blue-500">
        {/* TOP SECTION */}
        <FullWidthContentWrapper>
          {/* FANCY BACKGROUND */}
          <canvas
            id={canvasSelectorId}
            className="absolute h-full w-full bg-[#75abc6] transition-[filter] duration-[60s] ease-linear"
            style={{
              //@ts-expect-error see definition of style prop
              '--gradient-color-blue': '#75abc6',
              '--gradient-color-light-blue': '#9fc5d7',
              '--gradient-color-magenta': '#e157b4',
              '--gradient-color-light-magenta': '#ed9ad2'
            }}
          />
          {/* GIANT MOOV LOGO BACKGROUND */}
          <div className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 overflow-hidden">
            <svg
              viewBox="0 0 55 55"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute left-1/2 top-1/2 aspect-square h-full -translate-x-1/2 -translate-y-1/2 rotate-[30deg] opacity-30 lg:h-[130%]"
            >
              <rect x="0" y="0" width="15" height="15" rx="1" style={{ fill: '#f1f5f9' }} />
              <rect x="20" y="5" width="15" height="20" rx="1" style={{ fill: '#f1f5f9' }} />
              <rect x="40" y="20" width="15" height="35" rx="1" style={{ fill: '#f1f5f9' }} />
              <rect x="20" y="30" width="15" height="15" rx="1" style={{ fill: '#f1f5f9' }} />
              <rect x="40" y="0" width="15" height="15" rx="1" style={{ fill: '#f1f5f9' }} />
              <rect x="0" y="20" width="15" height="35" rx="1" style={{ fill: '#f1f5f9' }} />
            </svg>
          </div>

          <ContentWrapper className="pb-0 text-moov-blue-500">
            {/* HERO */}
            <section className="mb-16 flex flex-col items-center">
              <MarketingH1 className="mb-8 text-center lg:mb-16">
                {NewWayToBuySellTup[0]}
                <span className="text-magenta-500">{NewWayToBuySellTup[1]}</span>
                {NewWayToBuySellTup[2]}
              </MarketingH1>

              {/* SEARCH BAR */}
              <HomeEquipmentSearchBar />
              {/* METRICS */}
              <div className="flex flex-col items-center gap-x-3 gap-y-3 lg:mb-10 lg:flex-row lg:items-start lg:gap-x-6">
                <article className="text-center lg:text-center">
                  <MarketingH3 className="md:font-normal">
                    <CountUpOnceVisible
                      delay={0}
                      duration={3}
                      start={Math.floor(moov_metrics.listings_total_count * 0.9)}
                      end={moov_metrics.listings_total_count}
                      separator=","
                    />
                  </MarketingH3>
                  <div className="font-inter text-16spx  uppercase leading-tight tracking-widest">{loc.Common.ActiveListings}</div>
                </article>
                <div className="h-px self-stretch bg-moov-blue-500 lg:h-auto lg:w-px" />
                <article className="text-center lg:text-left">
                  <MarketingH3 className="md:font-normal">
                    <CountUpOnceVisible
                      delay={0}
                      duration={3}
                      start={Math.floor(moov_metrics.listings_total_value * 0.9)}
                      end={moov_metrics.listings_total_value}
                      prefix="$"
                      separator=","
                    />
                  </MarketingH3>
                  <div className="font-inter text-16spx uppercase leading-tight tracking-widest">{loc.HomeMain.TotalValue}</div>
                </article>
              </div>

              {/* LOGOS */}
              {config.logos.length > 0 && (
                <>
                  <div className="hidden font-inter text-16spx uppercase leading-tight tracking-widest lg:mb-4 lg:flex">
                    {loc.HomeMain.TrustedBy}
                  </div>
                  <div className="hidden h-fit flex-wrap items-center justify-around gap-x-5 lg:flex lg:justify-center lg:gap-x-16">
                    {/* LOGOS */}
                    {config.logos.map((logo) => (
                      <ExternalLink aria-label={logo.altText} href={logo.website} key={logo.imgUrl}>
                        <div className="relative flex h-16 w-20 items-center justify-center lg:w-44">
                          <Image src={logo.imgUrl} alt={logo.altText} layout="fill" objectFit="contain" />
                        </div>
                      </ExternalLink>
                    ))}
                  </div>
                </>
              )}
            </section>
          </ContentWrapper>
          <FullWidthContentWrapper>
            <div className="slanted-bg fix-jagged-edges absolute -bottom-[1px] w-full bg-white" />
            <ContentWrapper>
              <HeroWithVideoAndAnimation
                videoSrc={getVideoURL()}
                thumbnailSrc={IMAGE_URLS.INTRO_VIDEO_THUMBNAIL}
                tooltipMessage="Get a peek inside Moov"
              >
                <article className="mb-8 text-center lg:text-left">
                  <MarketingH3 className="mb-3 text-center font-extrabold md:text-left lg:mb-8">
                    {loc.HomeMain.FirstClassService}
                  </MarketingH3>
                  <p className="mb-6 text-center text-20spx text-smokey-gray-500 md:text-left">{loc.HomeMain.TransactWithConfidence}</p>
                </article>
                <div className="flex flex-col gap-x-4 gap-y-4 lg:flex-row">
                  <InternalLink href={routes.marketplace()} className="w-full">
                    <MoovButton variant="primary" className="w-full px-3 py-4 text-14spx leading-6" tabIndex={-1}>
                      {loc.Common.BrowseListings}
                    </MoovButton>
                  </InternalLink>
                  <MoovButton variant="secondary" onClick={handleContactClick} className="w-full px-3 py-4 text-14spx leading-6">
                    {loc.Common.ContactSales}
                  </MoovButton>
                </div>
              </HeroWithVideoAndAnimation>
            </ContentWrapper>
          </FullWidthContentWrapper>
          {/* MOVING EQUIPMENT ON A GLOBAL SCALE */}
          <FullWidthContentWrapper className="overflow-hidden bg-white pt-16">
            {/* SKEWED CONTAINER */}
            <FullWidthContentWrapper className="bg-smokey-gray-50  lg:bg-transparent">
              {/* CONTENT */}
              <ContentWrapper className="pb-12 pt-12 md:pt-32 lg:py-0">
                <div className="m-auto mb-8 text-center font-inter text-24spx font-bold leading-tight md:text-48spx lg:mb-10">
                  {loc.HomeMain.GlobalScale}
                </div>
                <ShippingMetrics colorVariant="magenta" className="lg:mb-20" />
              </ContentWrapper>
            </FullWidthContentWrapper>
          </FullWidthContentWrapper>
        </FullWidthContentWrapper>

        {/* HOW IT WORKS */}
        <FullWidthContentWrapper className="overflow-hidden">
          {/* SKEWED CONTAINER */}
          <FullWidthContentWrapper className="bg-moov-blue-500 pt-12 md:pt-20">
            {/* CONTENT */}
            <ContentWrapper className="origin-left pb-12 md:pb-20">
              <article className="text-center">
                <h2 className="lg:mg-4 mb-3 font-inter text-24spx font-bold leading-tight text-white md:text-48spx">
                  {loc.HomeMain.HowItWorks}
                </h2>
                <p className="font-lato text-16spx text-moov-blue-200 lg:text-20spx ">{loc.HomeMain.FindQualityFast}</p>
              </article>
              <div className="mt-8 flex flex-col gap-x-6 gap-y-8 md:mt-16 lg:flex-row">
                <div className="flex flex-1 flex-col gap-y-8 lg:gap-y-20">
                  <div className="rounded-2xl bg-magenta-100 p-6 pb-8 lg:px-10 lg:pb-8 lg:pt-8">
                    <h2 className="mb-4 text-center font-inter text-24spx font-bold leading-none md:text-48spx">{loc.HomeMain.Buyers}</h2>
                    <div className="mb-4 flex flex-col gap-y-4 lg:gap-y-8">
                      <HowItWorksItem label={loc.Common.Search} description={loc.Common.SearchDescription}></HowItWorksItem>
                      <HowItWorksItem
                        label={loc.HomeMain.CreateToolRequests}
                        description={loc.HomeMain.SimplifyEquipemtProcurement}
                      ></HowItWorksItem>
                      <HowItWorksItem
                        label={loc.HomeMain.ReviewToolMatches}
                        description={loc.HomeMain.ToolRequestsAndPreferences}
                      ></HowItWorksItem>
                      <HowItWorksItem
                        label={loc.HomeMain.AskTechnicalQuestions}
                        description={loc.HomeMain.NeedToKnowMoreSpecifics}
                      ></HowItWorksItem>
                      <HowItWorksItem
                        label={loc.HomeMain.PurchaseAndTrack}
                        description={loc.HomeMain.ExpertsInLogisticsAndPaperwork}
                      ></HowItWorksItem>
                    </div>
                    <InternalLink href={routes.marketplace()} className="w-full">
                      <MoovButton variant="primary" className="w-full px-3 py-4 text-14spx leading-6" tabIndex={-1}>
                        {loc.Common.BrowseListings}
                      </MoovButton>
                    </InternalLink>
                  </div>
                </div>
                <div className="flex flex-1 flex-col gap-y-8 lg:gap-y-20">
                  <div className="flex flex-1 flex-col rounded-2xl bg-crayola-green-50 p-6 pb-8 lg:px-10 lg:pb-8 lg:pt-8">
                    <h2 className="mb-4 text-center font-inter text-24spx font-bold leading-none md:text-48spx">{loc.HomeMain.Sellers}</h2>
                    <div className="mb-4 flex flex-1 flex-col gap-y-4 lg:gap-y-8">
                      <HowItWorksItem
                        label={loc.HomeMain.RegisterForAFreeAccount}
                        description={loc.HomeMain.SignUpInLessThanAMinute}
                      ></HowItWorksItem>
                      <HowItWorksItem
                        label={loc.HomeMain.EnterpriseManagementForListings}
                        description={loc.HomeMain.ListAndManageYourLargeVolumnOfEquipment}
                      ></HowItWorksItem>
                      <HowItWorksItem
                        label={loc.HomeMain.QandAForTechnicalQuestions}
                        description={loc.HomeMain.GetNotifiedWhenYouHaveABuyer}
                      ></HowItWorksItem>
                      <HowItWorksItem
                        label={loc.HomeMain.LogisticsHandled}
                        description={loc.HomeMain.KnowYourEquipmentWillBePurchased}
                      ></HowItWorksItem>
                      <HowItWorksItem label={loc.HomeMain.GetPaidFast} description={loc.HomeMain.MoovsSalesTeamArmed}></HowItWorksItem>
                    </div>
                    <InternalLink href={routes.sell()} className="w-full">
                      <MoovButton variant="quaternary" className="w-full px-3 py-4 text-14spx leading-6" tabIndex={-1}>
                        {loc.Common.CreateListing}
                      </MoovButton>
                    </InternalLink>
                  </div>
                </div>
              </div>
            </ContentWrapper>
          </FullWidthContentWrapper>
        </FullWidthContentWrapper>
        <FullWidthContentWrapper className="slanted-icon-bg relative overflow-hidden bg-moov-blue-600">
          <div className="bg-[url('https://d2pkkbyngq3xpw.cloudfront.net/moov_media/3.0-assets/moov-difference-bg.png')] bg-cover bg-center bg-no-repeat py-12 md:py-20">
            <ContentWrapper>
              <div className="flex flex-col items-center text-white lg:flex-row">
                <div className="mb-6 flex items-center justify-center gap-x-4 lg:mb-0 lg:w-2/3 xl:w-1/2">
                  <div className="h-10 w-10 lg:h-20 lg:w-20">
                    <Image src={IMAGE_URLS.MOOV_LOGO_ICON} alt="Moov Logo Icon" width="80px" height="80px" />
                  </div>
                  <h2 className="text-24spx font-extrabold leading-tight md:text-48spx">{loc.HomeMain.MoovDifference}</h2>
                </div>
                <article className="xl:w-1/2">
                  <MarketingSubheading>
                    <ul className="flex flex-col gap-y-4 text-18spx">
                      <li className="flex gap-x-2">
                        <CheckCircleIcon className="text-sky-blue-500" />
                        {loc.HomeMain.MoovDifferenceDescription1}
                      </li>
                      <li className="flex gap-x-2">
                        <CheckCircleIcon className="text-sky-blue-500" />
                        {loc.HomeMain.MoovDifferenceDescription2}
                      </li>
                      <li className="flex gap-x-2">
                        <CheckCircleIcon className="text-sky-blue-500" />
                        {loc.HomeMain.MoovDifferenceDescription3}
                      </li>
                      <li className="flex gap-x-2">
                        <CheckCircleIcon className="text-sky-blue-500" />
                        {loc.HomeMain.MoovDifferenceDescription4}
                      </li>
                      <li className="flex gap-x-2">
                        <CheckCircleIcon className="text-sky-blue-500" />
                        {loc.HomeMain.MoovDifferenceDescriptionConclusion}
                      </li>
                    </ul>
                  </MarketingSubheading>
                </article>
              </div>
            </ContentWrapper>
          </div>
        </FullWidthContentWrapper>

        {/* FEATURES */}
        <FullWidthContentWrapper className="bg-moov-blue-50">
          <ContentWrapper className="pt-16 text-center lg:mb-16">
            <div className="mb-4 font-lato text-16spx uppercase leading-tight tracking-widest text-magenta-500-old">
              {loc.HomeMain.Features}
            </div>
            <FeaturesSlider />
          </ContentWrapper>
        </FullWidthContentWrapper>

        {/* TESTIMONIALS */}
        <FullWidthContentWrapper className="bg-white">
          <ContentWrapper className="pb-16 pt-16 text-center lg:mb-16">
            <TestimonialsWidget />
          </ContentWrapper>
        </FullWidthContentWrapper>

        {/* BLOG */}
        <FullWidthContentWrapper className="bg-moov-blue-50">
          <ContentWrapper className="py-12 lg:py-16">
            <article className="mb-8 text-center">
              <MarketingOverlineMagenta className="mb-1">{loc.Common.Blog}</MarketingOverlineMagenta>
              <h2 className="font-inter text-36spx font-bold leading-tight lg:text-48spx">{loc.HomeMain.ReadTheLatest}</h2>
            </article>
            <ul className="grid gap-x-6 gap-y-6 lg:grid-cols-3">
              {blog_posts.slice(0, 3).map((post) => (
                <li className="rounded-2xl border-2 border-smokey-gray-200 bg-white p-4 lg:px-6 lg:py-8" key={post.title}>
                  <article className="flex flex-col gap-y-4">
                    <p className=" font-helvetica text-14spx leading-tight">{formatDate(post.created_at, 'en')}</p>
                    <MarketingH5>{post.title}</MarketingH5>
                    <SanitizedMarkdown
                      markdownText={`${post.copy.split(' ').splice(0, 19).join(' ')}...`}
                      className="font-lato text-16spx leading-snug lg:text-18spx"
                      regexFilter={/<p[^>]?>.*<\/p>/g}
                    />
                    <div className="font-lato text-16spx leading-snug lg:text-18spx"></div>
                    <LinkWithRightArrow
                      className="font-lato text-14spx font-bold leading-tight text-magenta-500"
                      href={routes.blogPost(post.slug, post.id)}
                    >
                      {loc.Common.ViewArticle}
                    </LinkWithRightArrow>
                  </article>
                </li>
              ))}
            </ul>
          </ContentWrapper>
        </FullWidthContentWrapper>

        {/* READY TO GET STARTED? BANNER */}
        <FullWidthContentWrapper className="bg-gradient-rainbow bg-cover bg-center">
          {/* BACKGROUND DIMMER */}
          <div className="absolute bottom-0 left-0 right-0 top-0 bg-white bg-opacity-50">
            <div className="h-full w-full bg-moov-difference bg-cover bg-center bg-no-repeat" />
          </div>

          {/* CONTENT */}
          <ContentWrapper className="flex flex-col items-center justify-center py-12 lg:py-20">
            <MarketingH3 className="lg:6 mb-4 text-center">
              {OverXCompaniesAndGrowingTup[0]}
              <CountUpOnceVisible delay={0} duration={3} start={0} end={companies_total_count} separator="," />
              {OverXCompaniesAndGrowingTup[2]}
            </MarketingH3>
            <InternalLink onClick={handleRegister} href={routes.register()}>
              <MoovButton variant="primary" className="px-7 text-14spx" tabIndex={-1}>
                {loc.HomeMain.JoinTheMoovment}
              </MoovButton>
            </InternalLink>
          </ContentWrapper>
        </FullWidthContentWrapper>
      </PageWrapperCustom>
    </>
  );
};

export default HomeMain;
